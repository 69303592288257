<template>
<div>
    <Header />
        <div class="banner bg-dark text-white">
            <div class="container">
                <div class="row">
                    <div class="col py-5">
                        <h1><b>Aviso de Privacidad</b></h1>
                    </div>
                </div>
            </div>
        </div>

        <div class="py-5 bg-gradient py-10">
            <div class="container">
                <div class="row pb-5">
                    <div class="col">

                        <p>En cumplimiento a la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, Rebe Lan, Asociación Civil en lo sucesivo Rebeca Lan A.C. está consciente de que la seguridad de su información personal por el uso de nuestra causa es importante. Tomamos seriamente la protección de sus datos personales. Por lo tanto, nos interesa que usted esté enterado  de cuáles datos conservamos y cuáles eliminamos. A través de este aviso de privacidad, le informamos acerca de nuestras medidas de seguridad.</p>

                        <p class="text-secret font-weight-bold">RECOLECCIÓN DE DATOS.</p>
                        <p>Usted puede proporcionarnos datos personales físicamente, electrónicamente o verbalmente, sin revelar sus datos personales. Cuando utiliza nuestro sitio Web, los datos se almacenan en nuestros servidores con fines preventivos, por ejemplo, los sitios Web que usted visita desde nuestro sitio y su dirección IP. Es probable que estos datos pudieran conducir a su identificación, sin embargo, no se usan con este fin. Los datos se usan con fines estadísticos y con el objeto de hacerle llegar información relativa a nuestras actividades y eventos, pero el usuario individual permanece anónimo. Rebeca Lan A.C. no provee datos de ninguna especie a organizaciones externas, nos hemos asegurado que se lleve a cabo, mediante medios técnicos y de organización, el reglamento de seguridad de datos.</p>

                        <p class="text-secret font-weight-bold">RECOLECCIÓN Y PROCESAMIENTO DE LOS DATOS PERSONALES.</p>
                        <p>Los datos personales se reúnen exclusivamente si usted nos los proporciona, es decir, como parte del registro a través del llenado de formas o correos electrónicos, como parte de una solicitud de contacto.<br>
                        La base de datos y su contenido permanece en Rebeca Lan A.C., y permanece en nuestros procesadores de datos o servidores y son responsabilidad nuestra. Sus datos personales no son proporcionados por nosotros para el uso de terceros en ninguna forma salvo para los fines propios de las actividades de Rebeca Lan A.C. referente a los requisitos de ingreso que las autoridades correspondientes nos solicitan; y/o a menos que hayamos obtenido su consentimiento o autorización para hacerlo.<br>
                        Mantendremos el control de y la responsabilidad del uso de cualquier información personal que nos proporcione.</p>

                        <p class="text-secret font-weight-bold">PROPÓSITOS DE USO.</p>
                        <p>La información que nosotros recopilamos se utilizará únicamente con el propósito de comunicarle las actividades y logros de nuestra Fundación, siempre y cuando usted hada dado su consentimiento, salvo disposición por ley en contrario.</p>

                        <p class="text-secret font-weight-bold">DERECHO DE ACCESO Y CORRECCIÓN.</p>
                        <p>Usted tiene el derecho de: (i) acceder a sus datos; (ii) solicitar la rectificación de datos erróneos o incompletos, para cuyo caso le notificaremos dichas rectificaciones; (iii) solicitar la cancelación de los datos, que puede involucrar el que sean bloqueados por un periodo o su total borrado, y (iv) oponerse al uso de sus datos de cualquier forma y de todo tipo de tratamiento. Sólo envíe un correo electrónico a la dirección proporcionada o contacte a la persona en la dirección que se muestra abajo.</p>

                        <p class="text-secret font-weight-bold">RETENCIÓN DE INFORMACIÓN.</p>
                        <p>Unicamente retenemos datos personales durante el tiempo que nos sea necesario para  comunicarle las actividades y logros de nuestra Fundación cuando usted lo haya solicitado o para el cual nos dio su consentimiento, salvo disposición por ley en contrario.</p>

                        <p class="text-secret font-weight-bold">SEGURIDAD</p>
                        <p>Rebeca Lan A.C., utiliza medidas de seguridad técnicas y de organización, a fin de proteger sus datos de manipulación casual o deliberada, pérdida, destrucción o acceso por personas no autorizadas. Como parte de la recolección y procesamiento de los datos personales, se codifica toda la información transferida para evitar mal uso de los datos por terceros. Nuestros procedimientos de seguridad son revisados continuamente con base al desarrollo de nuevas tecnologías.</p>

                        <p class="text-secret font-weight-bold">MENORES.</p>
                        <p>En vista de la importancia de la protección de la privacidad de los niños, nosotros no recopilamos, procesamos o utilizamos ninguna información relacionada con algún individuo que sepamos que es menor de 18 años sin el previo y verificable consentimiento de su representante legal. Tal representante legal tiene el derecho, a su solicitud, de ver la información que facilitó el menor y/o solicitar que la misma sea borrada.</p>

                        <p class="text-secret font-weight-bold">CONSENTIMIENTO.</p>
                        <p>Se entenderá que usted otorga su consentimiento al uso de sus datos personales, en la forma que ha quedado establecida en el presente Aviso de Privacidad, si no manifiesta su oposición por escrito al correo electrónico o dirección que se menciona más abajo.</p>

                        <p class="text-secret font-weight-bold">AVISO DE CONFIDENCIALIDAD</p>
                        <p>Este mensaje (y sus anexos) tiene por destinatario exclusivo a la persona o entidad a la que está dirigida, son confidenciales y contienen información de propiedad exclusiva de REBECA LAN, A.C. y sus afiliadas o subsidiarias; y están amparadas por el secreto profesional y/o propiedad intelectual. Si usted ha recibido este e-mail por error, por favor comuníquelo inmediatamente vía e-mail al origen y tenga la amabilidad de borrar y destruir el mensaje, al mismo tiempo deberá abstenerse de copiar o divulgar de cualquier forma su contenido. Muchas gracias.</p>

                    </div>
                </div>
            </div>
        </div>
    <Footer />
</div>
</template>

<style lang="less">
    @import "@/assets/css/colors.less";
    @import "@/assets/css/main.less";
    .bg-dark{
        overflow: hidden;
        position: relative;
        background: rgb(0,0,0);
        background: linear-gradient(90deg, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 100%);
        text-shadow: 0 1px 15px rgba(0,0,0,.8);

        &::before {
            background-image: url('../../assets/img/bg-image.jpg');
            content: "";
            background-size: cover;
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            opacity: 0.35;
        }
    }
</style>

<script>
import Header from '@/modules/Templates/Header'
import Footer from '@/modules/Templates/Footer'

export default {
    name: "avisoPrivacidad",
    components:{
        Header,
        Footer
    }
}
</script>

